<template>
    <div class='kolEdit'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="714px" :close-on-click-modal='false' @close="close">
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="details_info">
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList1" :key="index">
                        <div class="label" :class="type == 1 ? 'label1' : 'label4'">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList2" :key="index">
                        <div class="label">{{ item.label }}
                        </div>
                        <div class="value">
                            <el-link v-if="index == 3" type="primary" @click="linkUrl(item.value)">{{ item.value }}</el-link>
                            <div v-else-if="index == 4" class="img" @click="handleImgPreview(item.value)">
                                <el-image style="width: 100%; height: 100%" :src="item.value" fit="cover"></el-image>
                            </div>
                            <div v-else class="txt">{{ item.value }}</div>
                            <img v-if="index == 0 || index == 1" class="copy" @click="copy(item.value)" src="@/assets/images/user/copy.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList3" :key="index">
                        <div class="label label3">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                    <div class="time_box" v-if="btnType == 2">
                        <div class="label">入驻有效期：</div>
                        <div class="value">
                            <el-date-picker size="small" v-model="rzTimes" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="time_box" v-if="btnType == 3">
                        <div class="label">入驻有效期：</div>
                        <div class="value">
                            {{info.kol_started}} 至 {{info.kol_ended}}
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" v-if="btnType == 2">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
                    定</el-button>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        type: {
            type: [String, Number],
            default: ''
        },
        btnType: {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            subLoading: false,
            id: '',
            imgUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg', // 粉丝流量截图
            imgPreview: false,
            dialogImageUrl: '',
            rzTimes: [],
            startTime: '',
            endTime: '',
            info: {},
        }
    },
    computed: {
        title() {
            var s = ''
            if (this.btnType == 1) {
                // s = '添加'
            }
            if (this.btnType == 2) {
                s = this.info.type == 1 ? '编辑个人' : '编辑企业'
            }
            if (this.btnType == 3) {
                s = this.info.type == 1 ? '个人详情' : '企业详情'
            }
            return s
        },
        infoList1() {
            let infoList = []
            if (this.type == 1) {
                infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.member_id
                    },
                    {
                        label: '身份：',
                        value: this.info.type == 1 ? '个人' : '企业'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.member?.source_name || '--',
                    },
                    {
                        label: '姓名：',
                        value: this.info.name,
                    },
                    {
                        label: '身份证号：',
                        value: this.info.idc
                    },
                ]
            }
            if (this.type == 2) {
                infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.member_id
                    },
                    {
                        label: '身份：',
                        value: this.info.type == 1 ? '个人' : '企业'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.member?.source_name || '--',
                    },
                    {
                        label: '企业名称：',
                        value: this.info.corp_name,
                    },
                    {
                        label: '统一社会信息代码：',
                        value: this.info.credit_code,
                    },
                    {
                        label: '法人代表姓名：',
                        value: this.info.name,
                    },
                    {
                        label: '法人代表身份证号：',
                        value: this.info.idc
                    },
                ]
            }
            return infoList
        },
        infoList2() {
            let infoList = [
                {
                    label: '视频平台及账号ID：',
                    value: this.info.video_account,
                },
                {
                    label: 'PC版个人主页链接：',
                    value: this.info.home_url,
                },
                {
                    label: '推荐人：',
                    value: this.info.referrer_mobile || '无',
                },
                {
                    label: '签约：',
                    value: this.info.sign_url
                },
                {
                    label: '上传视频平台粉丝数量截图：',
                    value: this.info.fan_screen,
                },
            ]
            return infoList
        },
        infoList3() {
            let infoList = [
                {
                    label: '联系人：',
                    value: this.info.member?.nickname
                },
                {
                    label: '注册时间：',
                    value: this.info.member?.register_time
                },
                {
                    label: '提交入驻时间：',
                    value: this.info.create_time
                },
            ]
            return infoList
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        linkUrl(url) {
            window.open(url, "_blank");
        },
        copy(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getKolApplyDetails(this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                    if (this.info.kol_started) {
                        this.rzTimes = [this.info.kol_started, this.info.kol_ended]
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            if (this.rzTimes == null || this.rzTimes.length == 0) {
                this.$errMsg('请选择入驻有效期')
                return
            }
            this.subLoading = true
            let parmas = {
                createper: `${this.rzTimes[0]}|${this.rzTimes[1]}`
            }
            this.$userApi.getKolEdit(parmas, this.info.id).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.cancelBtn()
                    this.$emit('refreshList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
        close() {
            this.rzTimes = []
        },
    },
}
</script>

<style lang='scss' scoped>
.kolEdit {
    .details_info {
        .info_box {
            display: flex;
            flex-wrap: wrap;

            .info_item {
                display: flex;
                width: 50%;
                margin-bottom: 20px;

                .label {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .label1 {
                    width: 84px;
                }

                .label2 {
                }

                .label3 {
                    width: 98px;
                }

                .label4 {
                    width: 126px;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    align-items: center;

                    .img {
                        width: 130px;
                        height: 86px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .copy {
                        width: 16px;
                        height: 16px;
                        margin-left: 5px;
                    }
                }
            }

            .time_box {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .label {
                    width: 98px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .value {
                    width: 312px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .line {
            width: 674px;
            height: 2px;
            background: #d8d8d8;
            margin: 10px 0 30px 0;
        }
    }
}
</style>
