<!-- @Author: Yu_Bo -->
<template>
  <div class='kol_list'>
    <div class="vue_title">
      KOL列表
    </div>
    <div class="main">
      <search-params ref="SearchParams" :is_phone="true" :is_name="true" :is_identity="true" :is_time_field="true"
        :user_identity_list='user_identity_list' :time_field_list='time_field_list' :is_export='true' @search="search"
        @export='exportBtn'></search-params>
      <table-list :list="list" :loading="loading" @kolEditBtn="kolEditBtn" @kolDetailBtn="kolDetailBtn"
        @idsBtn='idsBtn'></table-list>
      <div class="page_box pr20">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
          @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- kol编辑 -->
    <kol-edit ref="KolEdit" :type="accountType" :btnType="btnType" @refreshList="getList"></kol-edit>
  </div>
</template>

<script>
  import EmptyData from '@/components/empty_data/empty_data.vue' //空数据
  import PagingPage from '@/components/paging_page/paging_page.vue' //分页
  import SearchParams from '../components/search/index.vue' // 筛选项
  import TableList from '../components/tableList/kolList.vue' // 列表
  import KolEdit from '../components/kolEdit/kolEdit.vue'

  export default {
    components: {
      EmptyData,
      PagingPage,
      SearchParams,
      TableList,
      KolEdit,
    },
    props: {},
    data() {
      return {
        accountType: 2, // 1 个人 2 企业
        btnType: 2, // 1 添加 2 编辑 3 详情
        list: [],
        params: {},
        page: 1,
        limit: 10,
        total: 0,
        loading: false,
        ids: [],
        //
        source_list: [],
        user_identity_list: [], //用户身份类型
        time_field_list: [], //时间条件
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getParams()
      this.getList()
    },
    mounted() {},
    methods: {
      getParams() {
        this.$userApi.getKolApplyListParams().then(res => {
          if (res.code == 1000) {
            this.user_identity_list = res.result.type
            this.time_field_list = res.result.search_time_type
          }
        })
      },
      search(params) {
        this.params.mobile = params.phone
        this.params.keyword = params.name
        this.params.type = params.identity_type
        if (params.time_field == 'createper') {
          this.params.createper = params.date ? `${params.date[0]}|${params.date[1]}` : ''
          this.params.audit_time = ''
        }
        if (params.time_field == 'audit_time') {
          this.params.audit_time = params.date ? `${params.date[0]}|${params.date[1]}` : ''
          this.params.createper = ''
        }
        this.page = 1
        this.list = []
        this.total = 0
        this.getList()
      },

      resetFrom() {
        this.$refs.SearchParams.resetFrom()
        this.page = 1
        this.list = []
        this.total = 0
        this.params = {}
        this.getList()
      },
      sizeChangeBtn(val) {
        this.limit = val
        this.page = 1
        this.getList();
      },
      currentChangeBtn(val) {
        this.page = val;
        this.getList();
      },
      getList() {
        const data = {
          page: this.page,
          limit: this.limit,
          ...this.params,
        }
        this.loading = true
        this.$userApi.getKolList(data).then(res => {
          this.loading = false

          if (res.code == 1000) {
            this.list = res.result.data
            this.total = res.result.total
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      // kol编辑
      kolEditBtn(id) {
        this.btnType = 2
        this.$refs.KolEdit.openDialogBtn(id)
      },
      // kol详情
      kolDetailBtn(id) {
        this.btnType = 3
        this.$refs.KolEdit.openDialogBtn(id)
      },
      // 处理选中的id
      idsBtn(arr) {
        this.ids = []
        arr.forEach(item => {
          this.ids.push(item.id)
        })
      },
      // 导出
      exportBtn(params) {
        var that = this
        var obj = {}
        obj.mobile = params.phone || ''
        obj.keyword = params.name || ''
        obj.type = params.identity_type || ''
        if (params.time_field == 'createper') {
          obj.createper = params.date ? `${params.date[0]}|${params.date[1]}` : ''
          obj.audit_time = ''
        }
        if (params.time_field == 'audit_time') {
          obj.audit_time = params.date ? `${params.date[0]}|${params.date[1]}` : ''
          obj.createper = ''
        }
        obj.ids = that.ids.join(',')
        that.$userApi.kolPassExport(obj).then(res => {
          return
        })
      },
    },
  }
</script>

<style lang='scss' scoped>
  @import "./index.scss";
</style>
