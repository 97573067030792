<!-- @Author: Yu_Bo -->
<template>
  <div class=''>
    <div class="table_wrap height">
      <el-table ref="table1" key="table1" :data="list" v-loading="loading" style="width: 100%"
        header-cell-class-name="table-header" cell-class-name="cell-class" @selection-change="handleSelectionChange">
        <template slot="empty">
          <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
        </template>
        <el-table-column type="selection" align="center" width="55" fixed="left">
        </el-table-column>
        <el-table-column prop="member_id" label="用户ID" fixed="left">
        </el-table-column>
        <el-table-column prop="mobile" label="登录手机账号" width="125" fixed="left">
        </el-table-column>
        <el-table-column label="姓名" width="145" fixed="left">
          <template slot-scope="scope">
            <div class="flex_box">
              <div v-if="scope.row.type == 1" class="isPerson">个人</div>
              <div v-if="scope.row.type == 2" class="isCompony">企业</div>
              <div class="flex_box">{{scope.row.name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="idc" label="身份证号" width="200">
        </el-table-column>
        <el-table-column prop="corp_name" label="企业名称">
        </el-table-column>
        <el-table-column prop="credit_code" label="统一社会信息代码" width="180">
        </el-table-column>
        <el-table-column prop="video_account" label="视频平台及账号ID" width="155">
        </el-table-column>
        <el-table-column prop="referrer_mobile" label="推荐人">
        </el-table-column>
        <el-table-column prop="source_name" label="注册来源">
        </el-table-column>
        <el-table-column prop="sign_url" label="签名">
        </el-table-column>
        <el-table-column label="入驻有效期" width="110">
          <template slot-scope="scope">
            <p>{{scope.row.kol_started}} <span v-if="scope.row.kol_started">/</span> {{scope.row.kol_ended}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="register_time" label="账户注册时间" width="160">
        </el-table-column>
        <el-table-column prop="create_time" label="提交入驻时间" width="160">
        </el-table-column>
        <el-table-column prop="audit_time" label="审核入驻时间" width="160">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="kolEditBtn(scope.row.id)">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="kolDetailBtn(scope.row.id)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
  import EmptyData from '@/components/empty_data/empty_data.vue' //空数据

  export default {
    components: {
      EmptyData,
    },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        multipleSelection:[],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // kol编辑
      kolEditBtn(id) {
        this.$emit('kolEditBtn', id)
      },
      // kol详情
      kolDetailBtn(id) {
        this.$emit('kolDetailBtn', id)
      },
      // kol审核
      kolAuditBtn() {
        this.$refs.KolAudit.openDialogBtn(null)
      },
      // kol审核详情
      kolAuditDetailBtn() {
        this.$refs.KolAuditDetail.openDialogBtn(null)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
        this.$emit('idsBtn', val)
      }
    },
  }
</script>

<style lang='scss' scoped>
  @import "./index.scss";

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
</style>